import { WithT } from 'i18next';
import React, { MouseEventHandler } from 'react';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowRight, faArrowLeft, faArrowUp, faArrowDown);

export interface ArrowProps extends WithT {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    className?: string;
}

function renderArrow(
    { className, onClick, t }: ArrowProps,
    icon: IconDefinition,
    labelI18nKey: string,
    ariaLabelI18nKey: string = labelI18nKey
): JSX.Element {
    return (
        <button type="button" className={className} onClick={onClick} aria-label={t(ariaLabelI18nKey)}>
            <FontAwesomeIcon aria-hidden="true" icon={icon} />
            <span className="visually-hidden">{t(labelI18nKey)}</span>
        </button>
    );
}

export function RightArrow(props: ArrowProps): JSX.Element {
    return renderArrow(props, faArrowRight, 'common:next');
}

export function DownArrow(props: ArrowProps): JSX.Element {
    return renderArrow(props, faArrowDown, 'common:next');
}

export function LeftArrow(props: ArrowProps): JSX.Element {
    return renderArrow(props, faArrowLeft, 'common:previous');
}

export function UpArrow(props: ArrowProps): JSX.Element {
    return renderArrow(props, faArrowUp, 'common:previous');
}
